import LinkIcon from '@duyank/icons/regular/Link';
import PlayCircleIcon from '@duyank/icons/regular/PlayCircle';
import { BoxSize } from '@lidojs/design-core';
import { Popover, useEditor } from '@lidojs/design-editor';
import {
  Button,
  Checkbox,
  Select,
  SelectItem,
  Snippet,
  Spinner,
} from '@nextui-org/react';
import { useEffect, useRef, useState } from 'react';
import { uploadHTMLAndGetURLs, uploadPageHTMLAndGetURL } from '../utils/download';
import { generateCollectionHTML, generateSlideCollectionHTML } from '../utils/export/generateCollectionHTML';
import { generateHTML } from '../utils/export/generateHTML';
import { generatePageLabels } from 'libs/design-editor/src/ultils/generatePageLabels';

export const PreviewButton = () => {
  const previewButtonRef = useRef<HTMLDivElement>(null);
  const [openExportSetting, setOpenExportSetting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pageExport, setPageExport] = useState(new Set(['0']));
  const [hasMobileFrame, setHasMobileFrame] = useState(false);
  const [previewUrl, setPreviewUrl] = useState('');
  const { query, activePage, pagesState } = useEditor((state) => ({
    activePage: state.activePage,
    pagesState: state.pages,
  }));

  const pages = query.serialize();

  const itemPages = generatePageLabels(pagesState).filter((page) => page.label.startsWith("Page"));

  const onChangePageExport = (value) => {
    setPageExport(new Set(value));
  };

  useEffect(() => {
    setPreviewUrl('');
  }, [openExportSetting, pageExport, hasMobileFrame]);

  const onGetPreview = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const pagesArray = Array.from(pageExport);

      if (pagesArray.length === 1) {
        const selectedPageIndex = pagesArray[0];
        const url = await uploadPageHTMLAndGetURL({page: pages[selectedPageIndex], hasMobileFrame, pages});
        window.open(url, '_blank');
      } else {
        const slides = await Promise.all(
          pagesArray.map(async (pageIndex) => {
            const html = await generateHTML({ page: pages[pageIndex], hasMobileFrame: false, pages });
            const size = pages[pageIndex].layers.ROOT.props.boxSize as BoxSize;
            return generateSlideCollectionHTML(html, size);
          })
        );

        const finalCollectionHTML = generateCollectionHTML(slides.join(''), hasMobileFrame);
        const finalURL = await uploadHTMLAndGetURLs(finalCollectionHTML);
        window.open(finalURL, '_blank');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const selectedPageIndex = Array.from(pageExport)[0];
    if (selectedPageIndex !== '0' && !pages[selectedPageIndex]) {
      setPageExport(new Set(['0']));
    }
  }, [pages, pageExport]);

  useEffect(() => {
    const isSubpage = pages[activePage]?.layers.ROOT.props?.relatesTo;
    if (activePage !== null && pageExport.size === 1 && !isSubpage) {
      setPageExport(new Set([activePage.toString()]));
    }
  }, [activePage]);

  const mainPages = pages.filter(item => !item.layers.ROOT.props?.relatesTo)

  return (
    <>
      <div
        ref={previewButtonRef}
        css={{
          display: 'flex',
          alignItems: 'center',
          color: '#fff',
          lineHeight: 1,
          background: '#3a3a4c',
          padding: '8px 14px',
          borderRadius: 8,
          cursor: 'pointer',
          ':hover': {
            background: 'rgba(58,58,76,0.5)',
          },
          '@media (max-width: 900px)': {
            display: 'none',
          },
        }}
        onClick={() => setOpenExportSetting(true)}
      >
        <div css={{ marginRight: 4, fontSize: 20 }}>
          <PlayCircleIcon />
        </div>{' '}
        Preview
      </div>
      <Popover
        anchorEl={previewButtonRef.current}
        offsets={{
          'bottom-end': { x: 0, y: 8 },
        }}
        open={openExportSetting}
        placement={'bottom-end'}
        onClose={() => setOpenExportSetting(false)}
      >
        <div css={{ padding: 16, width: 270, display: 'grid', rowGap: 10 }}>
          {mainPages?.length > 1 && (
            <div>
              <label>Select pages</label>
              <Select
                disallowEmptySelection
                className="max-w-xs text-black"
                color="primary"
                items={itemPages}
                name="fileType"
                selectedKeys={pageExport}
                selectionMode="multiple"
                variant="bordered"
                onSelectionChange={onChangePageExport}
              >
                {(item) => (
                  <SelectItem key={item.id}>{item.label}</SelectItem>
                )}
              </Select>
            </div>
          )}

          <Checkbox
            color='default'
            css={{
              paddingLeft: 10,
              letterSpacing: .3,
            }}
            isSelected={hasMobileFrame}
            onValueChange={(value) => setHasMobileFrame(value)}
          >
            With mobile frame
          </Checkbox>

          {previewUrl ? (
            <Snippet
              className="snippet-custom"
              css={{
                width: 238,
                '& pre': {
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                },
              }}
              symbol={
                <LinkIcon css={{ display: 'inline-block', marginRight: 4 }} />
              }
              variant="bordered"
            >
              {previewUrl}
            </Snippet>
          ) : (
            <Button
              css={{
                background: '#3a3a4c',
                color: '#fff',
                padding: '8px 16px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: 40,
                lineHeight: 40,
              }}
              disabled={isLoading || !pageExport}
              isLoading={isLoading}
              spinner={<Spinner className="mr-2" color="default" size="sm" />}
              onClick={() => onGetPreview()}
            >
              {isLoading ? 'Generating Preview' : 'Generate Preview'}
            </Button>
          )}
        </div>
      </Popover>
    </>
  );
};
