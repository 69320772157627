import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Button } from "@nextui-org/react";
import { FC } from "react";

interface ModalConfirmProps {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const ModalConfirm: FC<ModalConfirmProps> = ({ isOpen, onConfirm, onCancel }) => {
  return (
    <Modal isOpen={isOpen} onOpenChange={onCancel}>
      <ModalContent>
        <ModalHeader>Confirm action</ModalHeader>
        <ModalBody>If you apply this template, your changes will be lost. Are you sure you want to apply the template?</ModalBody>
        <ModalFooter>
          <Button color="danger" variant="light" onPress={onCancel}>
            Cancel
          </Button>
          <Button color="primary" onPress={onConfirm}>
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalConfirm;
