import { LayerComponentProps, LayerId, SerializedLayer, SerializedPage } from '@lidojs/design-core';
import { VideoLayerProps } from '@lidojs/design-editor';
import { signHTML } from '../../constant/exportable';
import { extractFonts } from './extractFonts';
import { extractProps } from './extractProps';
import { generateComponent } from './generateComponent';
import { generateContainer } from './generateContainer';
import { generateGroupLayerComponent } from './generateGroupLayer';
import { RedirectionProps, generateRedirectionHTML } from './generateRedirectionHTML';
import { generateValidURL } from './generateValidURL';
import { makeMapLayers } from './makeMapLayers';
import { generatePremiumHTML } from './premiumCreatives/generatePremiumHTML';
import { processLayers } from './proccessLayers';
import { scriptAdjustFontSize, scriptChangeSizeFrame } from './scriptsToExport';
import { mobileFrameStyles, videoControlStyles } from './stylesToExport';

export const generateHTML = async ({page, hasMobileFrame, pages}: {
  page: SerializedPage,
  hasMobileFrame?: boolean,
  pages?: SerializedPage[]
}
): Promise<string> => {
  const { layers } = page;
  const layersProccessed = await processLayers(layers);
  const { ROOT: container } = layersProccessed;

  if (container?.isPremium) {
    return await generatePremiumHTML({
      page: layersProccessed,
      hasMobileFrame,
      pages
    });
  } else {
    return generateSimpleHTML(layersProccessed, hasMobileFrame);
  }
};

const generateSimpleHTML = async (page:  Record<LayerId, SerializedLayer>, hasMobileFrame?: boolean): Promise<string> => {
  const { ROOT: container, ...components } = page;

  const positionLayers = makeMapLayers(container.child);

  const componentsHTMLArray = Object.keys(components).map((component) => {
    const zPosition = positionLayers[component];
    const componentElement = components[component];
    if (componentElement.child.length) {
      return generateGroupLayerComponent({
        component: componentElement,
        zPosition,
        id: component,
        components,
        container,
      });
    } else if (componentElement.parent === 'ROOT') {
      return generateComponent({
        component: componentElement,
        zPosition,
        id: component,
        container,
      });
    }
    return '';
  });

  const componentsHTML = componentsHTMLArray.join('');
  const hasCarousel = Object.values(components).some(
    (component) => component.type.resolvedName === 'CarouselLayer'
  );
  const hasAnimation = Object.values(components).some(
    (component) => component.props?.animation
  );
  const hasText = Object.values(components).some(component => component.type.resolvedName === 'TextLayer');
  const hasFrame = Object.values(components).some(component => component.type.resolvedName === 'FrameLayer');
  const hasVideoControls = Object.values(components).some(component =>
      component.type.resolvedName === 'VideoLayer' &&
      (component.props as unknown as VideoLayerProps)?.video.controls);

  const fonts = extractFonts(page);
  const props = extractProps(page);
  const widthContainer =
    (container.props as LayerComponentProps)?.boxSize?.width || 1200;
  const heightContainer = (container.props as LayerComponentProps)?.boxSize?.height || 627;
  const isContainer = true;
  const redirectionContainer = generateRedirectionHTML(
    container.props.hyperlink, isContainer
  );
  const containerHTML = generateContainer(container, hasMobileFrame).replace(
    '</div>',
    `${componentsHTML}</div>`
  );

  const finalHTML = `
    ${signHTML}
    <!DOCTYPE html>
    <html lang="es">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <meta name="ad.size" content="width=${widthContainer},height=${heightContainer}">
        <title>Creative</title>
        ${
          hasCarousel
            ? `      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/swiper@8/swiper-bundle.min.css" />
      <script src="https://cdn.jsdelivr.net/npm/swiper@8/swiper-bundle.min.js"></script>`
            : ''
        }
        ${
          hasAnimation
            ? `
              <link
                rel="stylesheet"
                href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
              />
            `
            : ''
        }
        ${container?.props?.hyperlink && (
          `<script type="text/javascript">
            var clickTag = "${generateValidURL((container?.props?.hyperlink as RedirectionProps)?.linkRedirect)}"
          </script>`
        )}

        <style>
            ${fonts}
            * { margin: 0; padding: 0; box-sizing: border-box;}
            [data-type="SvgLayer"] svg{
              width: 100%;
              height: auto;
            }
            ${hasMobileFrame
              ? mobileFrameStyles
              : `body{
                  background-color: ${container?.props?.color ?? 'white'};
                }`
            }
            ${hasVideoControls ?
              videoControlStyles : ''
            }
        </style>
    </head>
    <body ${redirectionContainer}>
      ${containerHTML}
      <script>
        const props = ${JSON.stringify(props)}
      </script>
      <script>
        function redirectToURL(url, event) {
          event.stopPropagation();
          window.location.href = url;
        }

        function redirectClickTag(url, event) {
          event.stopPropagation();
          window.open(url, "_blank");
        }
      </script>
      ${hasText ? scriptAdjustFontSize(widthContainer) : ''}
      ${hasFrame ? scriptChangeSizeFrame : ''}
    </body>
    </html>
    ${signHTML}`;

  return finalHTML;
}
